import { useMemo } from "react";
import { Typography, useDataGrid, DataGrid, List } from "@pankod/refine-mui";
import useLocales from "locales/useLocales";

export default function CustomList({ title, columns, resource, canCreate }) {
  const { currentLang } = useLocales();
  const columsMemo = useMemo(() => columns, []);

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        locale: currentLang.value || "en",
        populate: "*",
      },
    });

  return (
    <List
      resource={resource}
      createButtonProps={{ size: "small" }}
      canCreate={canCreate}
      headerProps={{
        title: <Typography variant="h5">{title}</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columsMemo}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
