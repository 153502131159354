import React from "react";

import { useRegister, useLogin } from "@pankod/refine-core";
import { Grid, Card, Typography } from "@pankod/refine-mui";

export default function Register() {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={11}
          container
          justifyContent="center"
          alignItems="center"
        >
          <img src="/oops.png" alt="Logo" style={{ height: 300 }} />
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h4" align="center">
              Sorry, you can't do that!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
