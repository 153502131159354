import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";

export default {
  title: "words",
  resource: "words",
  canCreate: true,
  columns: [
    { field: "content", headerName: "Word", flex: 1, minWidth: 300 },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 300,
      renderCell: function render(params) {
        return <div>{new Date(params.row.createdAt).toLocaleDateString()}</div>;
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      minWidth: 50,
      renderCell: function render(params) {
        return (
          <Stack direction="row" spacing={1}>
            <EditButton hideText recordItemId={params.row.id} />
            <DeleteButton hideText recordItemId={params.row.id} />
          </Stack>
        );
      },
    },
  ],
};
