import { useState, useEffect } from "react";

import { Create, Edit, Grid, Typography, Button } from "@pankod/refine-mui";
import { useForm } from "@pankod/refine-react-hook-form";
import { toast } from "react-toastify";
import { normalizeData } from "utils/utils";
import useLocales from "locales/useLocales";
import axios from "utils/axios";

export default function Comp({ isCreate, routeName, Fields }) {
  const {
    refineCore: { formLoading, queryResult },
    saveButtonProps,
    register,
    control,
    formState: { errors },
    getValues,
    setError,
    setValue,
    watch,
  } = useForm();

  const { currentLang, defaultLang } = useLocales();
  const [localeExists, setLocaleExists] = useState(true);
  const [defaultLocaleData, setDefaultLocaleData] = useState(null);

  useEffect(() => {
    if (!isCreate && !formLoading && queryResult?.data?.data?.id) {
      console.log(queryResult.data.data);
      let localizations = queryResult.data.data.localizations;

      if (!localizations) {
        // this content type is not localized
        setDefaultLocaleData(queryResult.data.data);
        setLocaleExists(false);
        return;
      }

      if (queryResult.data.data.locale == currentLang.value) {
        setLocaleExists(queryResult.data.data);
        if (queryResult.data.data.locale == defaultLang.value) {
          setDefaultLocaleData(queryResult.data.data);
        } else {
          let defLang = localizations.find((l) => l.locale == defaultLang);
          console.log(defLang);
          if (defLang)
            axios
              .get(`/api/${routeName}/${defLang.id}?populate=deep,2`)
              .then((res) => {
                setDefaultLocaleData(normalizeData(res.data));
                console.log(normalizeData(res.data));
              })
              .catch((err) => {
                console.log(err);
                toast.error("Something went wrong");
              });
        }
      } else {
        let languageExists = (localizations || []).find(
          (l) => l.locale == currentLang.value
        );
        if (languageExists) {
          window.open(`/${routeName}/edit/${languageExists.id}`, "_self");
        }
        setLocaleExists(languageExists);
      }
    }
  }, [currentLang.value, formLoading]);

  if (isCreate) {
    return (
      <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Fields
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
        />
      </Create>
    );
  } else {
    if (!localeExists) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item>
            <Typography variant="h6" gutterBottom component="div">
              This language does not exist. Please create it first.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                axios
                  .post(
                    `/api/${routeName}/${queryResult?.data?.data?.id}/localizations`,
                    {
                      locale: currentLang.value,
                    }
                  )
                  .then((res) => {
                    console.log(res.data);
                    window.open(`/${routeName}/edit/${res.data.id}`, "_self");
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error("Something went wrong");
                  });
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
        <Fields
          defaultLanguageRecord={defaultLocaleData}
          register={register}
          control={control}
          errors={errors}
          getValues={getValues}
          setError={setError}
          setValue={setValue}
          watch={watch}
        />
      </Edit>
    );
  }
}
