export const accessControlProvider = {
  can: async ({ resource, action, params }) => {
    let user = localStorage.getItem("user");
    if (user) user = JSON.parse(user);
    else
      return Promise.resolve({
        can: false,
        reason: "Unauthorized",
      });
    if (user != null) return Promise.resolve({ can: true });
    return Promise.resolve({
      can: false,
      reason: "Unauthorized",
    });
  },
};
