import { useState, useEffect } from "react";
import { useNavigate } from "@pankod/refine-react-router-v6";
import { Grid, Card, Button } from "@pankod/refine-mui";

import axios from "utils/axios";
import { normalizeData } from "utils/utils";

export default function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [word, setWords] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    const fetchWords = async () => {
      const { data } = await axios.get("/api/words");
      let norm = normalizeData(data);
      setWords(norm[norm.length - 1]);
    };
    fetchUser();
    fetchWords();
  }, []);

  return (
    <Grid container spacing={5} justifyContent="space-evenly">
      <Grid item md={5} xs={11} sx={{ mt: -7, mb: 3 }}>
        <Card sx={{ borderRadius: 10, height: "100%" }}>
          <Grid
            item
            xs={12}
            container
            alignItems="space-between"
            p={3}
            sx={{ height: "100%" }}
          >
            <Grid item xs={12} container justifyContent="center">
              {user && (
                <h1 style={{ paddingTop: 10 }}>{`Welcome ${user.username}`}</h1>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Grid item md={5} xs={11} sx={{ mt: 3 }}>
          <Card sx={{ borderRadius: 10, height: "100%" }}>
            <Grid
              item
              xs={12}
              container
              alignItems="space-between"
              p={3}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} container justifyContent="center">
                <h2 style={{ paddingTop: 10 }}>Latest Word:</h2>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <h1 style={{ paddingTop: 10, fontSize: "3em" }}>
                  {word?.content}
                </h1>
              </Grid>
              <Grid item xs={12} container justifyContent="center">
                <h1 style={{ paddingTop: 10 }}>
                  {word?.createdAt.slice(0, 10)}
                </h1>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  // set the browser to be fullscreen
                  document.documentElement.requestFullscreen();
                  navigate("/app");
                }}
              >
                Open App
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
