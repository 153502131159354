import React from "react";

const FullScreenWebView = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <iframe
        src="https://www.dailyword.xyz/app/index.html"
        style={{ width: "100%", height: "100%" }}
        frameBorder={0}
      />
    </div>
  );
};

export default FullScreenWebView;
