import { useState, useEffect } from "react";

import {
  TextField,
  MenuItem,
  Grid,
  Stack,
  Switch,
  Typography,
  Autocomplete,
  Divider,
} from "@pankod/refine-mui";
import { Controller } from "@pankod/refine-react-hook-form";
import MultiSelect from "components/MultiSelect";
import MultiSelectCheckbox from "components/MultiSelect-Checkbox";
import UploadZone from "components/UploadZone";
import GalleryManager from "components/GalleryManager";
import QuillEditor from "components/editor";
import useLocales from "locales/useLocales";

export default function CompFields({
  fieldVariants,
  label,
  name,
  variant,
  required,
  defaultValue,
  defaultLanguageRecord,
  type,
  options,
  accessor,
  lines,
  xs,
  md,
  width,
  height,
  disabled,
  register,
  control,
  errors,
  setError,
  getValues,
  setValue,
  watch,
  elevation,
  localized,
}) {
  const { currentLang, defaultLang } = useLocales();

  const [autocompleteValue, setAutocompleteValue] = useState(null);

  useEffect(() => {
    if (
      (type == "autocomplete" ||
        type == "multi-select" ||
        type == "multi-select-checkbox") &&
      defaultValue &&
      autocompleteValue == null
    ) {
      setValue(name, defaultValue, { shouldValidate: true });
      setAutocompleteValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (defaultLanguageRecord && currentLang.value != defaultLang.value) {
      console.log("Setting", defaultLanguageRecord[name]);
      if (!localized)
        setValue(name, defaultLanguageRecord[name], { shouldValidate: true });
    }
  }, [currentLang.value, defaultLanguageRecord]);

  const defaultValueData =
    defaultLanguageRecord && currentLang.value != defaultLang.value
      ? defaultLanguageRecord[name]
      : defaultValue || watch(name);

  disabled =
    disabled ||
    (!localized &&
      defaultLanguageRecord &&
      currentLang.value != defaultLang.value);

  if (type == "title")
    return (
      <Grid item xs={12} sx={{ height: height || "100%" }}>
        <Typography variant={variant} gutterBottom component="div">
          {label}
        </Typography>
      </Grid>
    );
  if (type == "space")
    return <Grid item xs={12} sx={{ height: height || "100%" }}></Grid>;
  if (type == "divider")
    return (
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        sx={{ height: height || "100%" }}
      >
        <Grid item xs={xs || 12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      </Grid>
    );
  if (
    type == "text" ||
    type == "number" ||
    type == "date" ||
    type == "time" ||
    type == "datetime"
  )
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <TextField
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "standard"}
          defaultValue={defaultValueData}
          fullWidth
          label={label}
          type={type == "datetime" ? "datetime-local" : type}
          multiline={lines && lines > 1}
          rows={lines}
          disabled={disabled}
        />
      </Grid>
    );
  if (type == "switch")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Stack direction="row" alignItems="center" spacing={2} mt={3}>
          <Typography variant="subtitle1" color="text.secondary">
            {label}
          </Typography>
          <Switch
            {...register("is_included", {})}
            control={control}
            checked={defaultValueData}
            disabled={disabled}
          />
        </Stack>
      </Grid>
    );
  if (type == "select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <TextField
          {...register(name, {
            required: required ? label + " Obbligatorio" : false,
          })}
          InputLabelProps={{
            shrink: true,
          }}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          required={required}
          variant={fieldVariants || "standard"}
          defaultValue={defaultValueData}
          fullWidth
          label={label}
          select
          value={getValues(name) || ""}
          onChange={(e) => {
            console.log(e.target.value);
            setValue(name, e.target.value, { shouldValidate: true });
          }}
          disabled={disabled}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  if (type == "autocomplete")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          rules={{ required: required ? label + " Obbligatorio" : false }}
          render={({ field }) => (
            <Autocomplete
              {...options}
              {...field}
              value={field.value || autocompleteValue}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              getOptionLabel={(item) => {
                return item[accessor] ? item[accessor] : "";
              }}
              isOptionEqualToValue={(option, value) => {
                return value === undefined || option.id === value.id;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  variant={fieldVariants || "outlined"}
                  error={!!errors[name]}
                  helperText={errors[name]?.message}
                  required
                />
              )}
            />
          )}
        />
      </Grid>
    );
  if (type == "multi-select")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          disabled={disabled}
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <MultiSelect
                disabled={disabled}
                name={label}
                variant={fieldVariants || "standard"}
                options={options}
                value={field.value || autocompleteValue}
                defaultValue={defaultValueData}
                onChange={(val) => {
                  field.onChange(val);
                  setValue(name, val, { shouldValidate: true });
                }}
              />
            );
          }}
        />
      </Grid>
    );
  if (type == "multi-select-checkbox")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <MultiSelectCheckbox
                disabled={disabled}
                name={label}
                variant={fieldVariants || "standard"}
                options={options}
                value={field.value || autocompleteValue}
                defaultValue={defaultValueData}
                onChange={(val) => {
                  field.onChange(val);
                  setValue(name, val, { shouldValidate: true });
                }}
              />
            );
          }}
        />
      </Grid>
    );
  if (type == "image")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <UploadZone
          width={width}
          height={height}
          elevation={elevation}
          disabled={disabled}
          register={register}
          errors={errors}
          setError={setError}
          setValue={setValue}
          defaultValue={defaultValueData}
          fieldName={name}
          required={required}
        />
      </Grid>
    );
  if (type == "gallery")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          control={control}
          name={name}
          rules={{ required: required ? label + " Obbligatorio" : false }}
          render={({ field }) => (
            <GalleryManager
              fieldName={field.name}
              control={control}
              errors={errors}
              setError={setError}
              defaultValue={watch(field.name)}
              getValues={getValues}
              setValue={setValue}
              watch={watch}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    );
  if (type == "editor")
    return (
      <Grid item xs={xs || 12} md={md || 12} sx={{ height: height || "100%" }}>
        <Controller
          control={control}
          name={name}
          rules={{ required: required ? label + " Obbligatorio" : false }}
          render={({ field, fieldState: { error } }) => (
            <QuillEditor
              name={name}
              field={field}
              error={error}
              disabled={disabled}
            />
          )}
        />
      </Grid>
    );
}
